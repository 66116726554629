import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/index")
    ),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/admin/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/admin/admin-add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/admin/admin-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/admin/admin-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/AdminProfile")
    ),
  },

  // user=======>

  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },

  // topics=======>

  {
    key: "topics.list",
    path: `${APP_PREFIX_PATH}/topics/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/topic/topicList")
    ),
  },
  {
    key: "topics.add",
    path: `${APP_PREFIX_PATH}/topics/add`,
    component: React.lazy(() => import("views/app-views/pages/topic/TopicAdd")),
  },
  {
    key: "topics.edit",
    path: `${APP_PREFIX_PATH}/topics/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/topic/TopicAdd")),
  },

  {
    key: "posts.list",
    path: `${APP_PREFIX_PATH}/posts/list`,
    component: React.lazy(() => import("views/app-views/pages/post/postList")),
  },
  {
    key: "posts.add",
    path: `${APP_PREFIX_PATH}/posts/add`,
    component: React.lazy(() => import("views/app-views/pages/post/PostAdd")),
  },
  {
    key: "posts.edit",
    path: `${APP_PREFIX_PATH}/posts/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/post/PostAdd")),
  },

 // ------------------------Version Routes-------------------------

  {
    key: "versions.list",
    path: `${APP_PREFIX_PATH}/versions/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/version/versionList")
    ),
  },
  {
    key: "versions.add",
    path: `${APP_PREFIX_PATH}/versions/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/version/VersionAdd")
    ),
  },
  {
    key: "versions.edit",
    path: `${APP_PREFIX_PATH}/versions/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/version/VersionAdd")
    ),
  },
 // ------------------------Language Routes-------------------------
 {
  key: "languages.list",
  path: `${APP_PREFIX_PATH}/languages/list`,
  component: React.lazy(() => import("views/app-views/pages/languages/Languages")),
},
{
  key: "languages.add",
  path: `${APP_PREFIX_PATH}/languages/add`,
  component: React.lazy(() =>
    import("views/app-views/pages/languages/AddLanguage")
  ),
},
{
  key: "languages.edit",
  path: `${APP_PREFIX_PATH}/languages/edit/:id`,
  component: React.lazy(() =>
    import("views/app-views/pages/languages/AddLanguage")
  ),
},

  // ------------------------Book Routes-------------------------
  {
    key: "books.list",
    path: `${APP_PREFIX_PATH}/books/list`,
    component: React.lazy(() => import("views/app-views/pages/book/bookList")),
  },
  {
    key: "books.add",
    path: `${APP_PREFIX_PATH}/books/add`,
    component: React.lazy(() => import("views/app-views/pages/book/BookAdd")),
  },
  {
    key: "books.edit",
    path: `${APP_PREFIX_PATH}/books/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/book/BookAdd")),
  },
  // ------------------------Chapter Routes-------------------------
  {
    key: "chapter.list",
    path: `${APP_PREFIX_PATH}/chapter/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/chapter/chapterList")
    ),
  },
  {
    key: "books.add",
    path: `${APP_PREFIX_PATH}/chapter/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/chapter/ChapterAdd")
    ),
  },
  {
    key: "books.edit",
    path: `${APP_PREFIX_PATH}/chapter/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/chapter/ChapterAdd")
    ),
  },
  // ------------------------Testament Routes-------------------------
  {
    key: "testament.list",
    path: `${APP_PREFIX_PATH}/testament/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/testament/testamentList")
    ),
  },
  {
    key: "testament.add",
    path: `${APP_PREFIX_PATH}/testament/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/testament/TestamentAdd")
    ),
  },
  {
    key: "testament.edit",
    path: `${APP_PREFIX_PATH}/testament/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/testament/TestamentAdd")
    ),
  },

  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/media/MediaList")
    ),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  {
    key: "cms.emails.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/email/emailList")
    ),
  },
  {
    key: "cms.emails.edit",
    path: `${APP_PREFIX_PATH}/email-templates/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.emails.add",
    path: `${APP_PREFIX_PATH}/email-templates/add`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.faqs.list",
    path: `${APP_PREFIX_PATH}/faqs/list`,
    component: React.lazy(() => import("views/app-views/pages/faq/faqList")),
  },
  {
    key: "cms.faqs.edit",
    path: `${APP_PREFIX_PATH}/faqs/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqAdd")),
  },
  {
    key: "cms.faqs.add",
    path: `${APP_PREFIX_PATH}/faqs/add`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqAdd")),
  },
  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/pages/page/pageList")),
  },
  {
    key: "cms.pages.edit",
    path: `${APP_PREFIX_PATH}/pages/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "cms.pages.add",
    path: `${APP_PREFIX_PATH}/pages/add`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "cms.pages.color",
    path: `${APP_PREFIX_PATH}/pages/ColorList`,
    component: React.lazy(() =>
      import("views/app-views/pages/color/ColorLists")
    ),
  },
];
