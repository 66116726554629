import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/reset.css";
import { socket, SocketContext } from './socket/socketio';

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <SocketContext.Provider value={socket}>
  <App />
  </SocketContext.Provider>
);

serviceWorker.unregister();
